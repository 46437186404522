<script setup>
import DialogModal from '@/Components/DialogModal.vue';
import { computed, nextTick, ref, watch } from 'vue';
import { join_names } from '@/lib/helpers';
import SerialNumberSingleScanWindow from '@/Pages/Pack/Partials/SerialNumberSingleScanWindow.vue';
import SerialNumberBulkScanWindow from '@/Pages/Pack/Partials/SerialNumberBulkScanWindow.vue';
import SerialNumberSN1 from '@/Pages/Pack/Partials/SerialNumberSN1.vue';

const show = ref(false);
const tab = ref('single');
const line = ref(null);
const remainingScanQuantity = ref(null);
const scannerLines = ref(null);
const singleScanWindowRef = ref(null);
const bulkScanWindowRef = ref(null);
const scannedSerialNumbers = ref([]);

const itemTitle = computed(() => {
    return line.value
        ? join_names(
              line.value.item.description,
              join_names(
                  line.value.item.number,
                  line.value.item.sku,
                  null,
                  true,
              ),
              ', ',
          )
        : null;
});

let scanDataTemplate = null;
let promiseResolve = null;
// eslint-disable-next-line no-unused-vars
let promiseReject = null;
const scan = (scan, deliveryNoteLine, scanLines, remainingLineItemQuantity) => {
    line.value = deliveryNoteLine;
    scannerLines.value = scanLines;
    remainingScanQuantity.value = remainingLineItemQuantity;
    scanDataTemplate = scan;

    show.value = true;
    return new Promise((resolve, reject) => {
        promiseResolve = resolve;
        promiseReject = reject;
    });
};

// TODO: WHY????
watch(show, async (value) => {
    if (value) {
        await nextTick();
    }
});

const resetWindowData = () => {
    if (line.value?.item?.project !== 'SN1') {
        singleScanWindowRef.value.reset();
        bulkScanWindowRef.value.reset();
    }
};
const reset = () => {
    tab.value = 'single';
    resetWindowData();
};
const addScan = (serialNumbers) => {
    const scan = JSON.parse(JSON.stringify(scanDataTemplate));
    scan.id = Date.now();
    scan.data.serial_numbers = serialNumbers;
    scan.quantity = 1;
    scannerLines.value.push(scan);
};

const saveSingleScan = (scan) => {
    scannedSerialNumbers.value.push(scan.serial_number + '_' + scan.mic1);

    addScan({
        ...scan,
    });

    promiseResolve(true);
    show.value = false;

    reset();
};

const saveBulkScans = (scans) => {
    scans.forEach((scan) => {
        addScan({
            ...scan,
        });
    });

    promiseResolve(true);
    show.value = false;

    reset();
};

watch(tab, async () => {
    resetWindowData();
    await nextTick();
    if (tab.value === 'single') {
        singleScanWindowRef.value.focusFirstInput();
    } else {
        bulkScanWindowRef.value.focusFirstInput();
    }
});
defineExpose({
    scan,
});
</script>

<template>
    <DialogModal :show="show" :persistent="false" @keydown.stop>
        <template #title>{{ itemTitle }}</template>
        <template #content>
            <template v-if="line.item.project !== 'SN1'">
                <v-tabs v-model="tab" color="primary" class="tw-mb-2">
                    <v-tab value="single">{{ $t('Single') }}</v-tab>
                    <v-tab value="bulk">{{ $t('Multi') }}</v-tab>
                </v-tabs>
                <v-window v-model="tab">
                    <SerialNumberSingleScanWindow
                        ref="singleScanWindowRef"
                        :line="line"
                        :scanned-serial-numbers
                        @scan="saveSingleScan"
                    />
                    <SerialNumberBulkScanWindow
                        ref="bulkScanWindowRef"
                        :line="line"
                        :remaining-scan-quantity="remainingScanQuantity"
                        @scan="saveBulkScans"
                        @save="saveBulkScans"
                    />
                </v-window>
            </template>
            <template v-else>
                <SerialNumberSN1 :line @scan="saveSingleScan"></SerialNumberSN1>
            </template>
        </template>
    </DialogModal>
</template>

<style scoped></style>
